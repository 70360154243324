import React, { useState, useEffect, type ReactElement } from "react";
import { Checkbox, Input, Button, Alert, Typography } from "antd";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { signIn, signInWithRedirect, type SignInInput } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth";

import { useNavigate } from "react-router-dom";
import Copyright from "../Components/Copyright";
import { useMutation } from "@tanstack/react-query";
import { createMetric } from "src/Services/Api/metric";

export const Login = ({ getUser }: { getUser: any }): ReactElement => {
  const [error, setError] = useState<string>();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateLogin = async () => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    if (idToken) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    validateLogin();
  });

  const handleSignIn = async ({ username, password }: SignInInput) => {
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });

      if (isSignedIn) {
        setLoading(false);
        const user = await getUser();
        await createMetric({
          type: "user.login",
          asset: user.userId,
        });
      }
    } catch (err: any) {
      console.log("error signing in", err);
      setLoading(false);
      setError(err.message);
    }
  };

  const getLoginFlow = async ({ username }) => {
    setLoading(true);
    const domain = username.split("@")[1];

    if (domain && domain === "auth0.net") {
      console.log("Got Oxyzn Domain");
      signInWithRedirect({
        provider: {
          custom: "Auth0",
        },
      });
    } else {
      setPasswordVisible(true);
      setLoading(false);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      {/* <CssBaseline /> */}

      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5}>
        <div
          style={{
            marginTop: "80px",
            marginLeft: "40px",
            marginRight: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/logo.png"
            style={{ height: "60px", marginBottom: "20px" }}
          />

          <Typography.Title level={5}>Sign in</Typography.Title>
          {error && (
            <Alert message={error} type="error" style={{ marginTop: "10px" }} />
          )}

          <div>
            <Input
              required
              disabled={passwordVisible}
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setError(undefined);
                setUsername(e.target.value.toLowerCase());
              }}
              style={{ marginTop: "20px" }}
            />
            <Input
              required
              name="password"
              placeholder="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setError(undefined);
                setPassword(e.target.value);
              }}
              style={{
                marginTop: "20px",
                display: passwordVisible ? "" : "none",
              }}
            />
            <div style={{ margin: "20px" }}>
              <FormControlLabel
                control={<Checkbox checked={false} style={{ margin: "5px" }} />}
                label="Remember me"
              />

              <Button
                hidden={passwordVisible}
                id="login"
                type="primary"
                loading={loading}
                onClick={() => {
                  if (!username) {
                    setError("Please enter your email");
                  } else if (!password) {
                    setError("Please enter your password");
                  } else {
                    setLoading(true);
                    handleSignIn({ username, password });
                  }
                }}
                style={{
                  float: "right",
                  display: passwordVisible ? "" : "none",
                }}
              >
                Log In
              </Button>
              <Button
                hidden={!passwordVisible}
                id="next"
                type="primary"
                loading={loading}
                onClick={() => {
                  if (!username) {
                    setError("Please enter your email");
                  } else {
                    setLoading(true);
                    getLoginFlow({ username });
                  }
                }}
                style={{
                  float: "right",
                  display: passwordVisible ? "none" : "",
                }}
              >
                Next
              </Button>
            </div>

            <Grid container>
              <Grid item xs>
                <Button
                  type="link"
                  href="/forgot-password"
                  style={{ fontSize: "12px" }}
                >
                  Forgot password?
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="link"
                  href="/register"
                  style={{ fontSize: "12px" }}
                >
                  {"Don't have an account? Sign Up"}
                </Button>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
