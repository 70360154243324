import React, { useState } from "react";
import { Table, Button, Dropdown, Tag } from "antd";
import type { TableProps, MenuProps } from "antd";
import NiceModal from "@ebay/nice-modal-react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { listContent } from "src/Services/Api/content.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faStar } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import ContentModal from "src/Modals/content.modal";

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: any; //Parameters<GetProp<TableProps, "onChange">>[1];
}

const Videos = () => {
  const { t, i18n } = useTranslation();

  const items: MenuProps["items"] = [
    { key: "1", label: t("EDIT", "Edit") },
    // { key: "2", label: "Delete", disabled: true },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchVideos = async ({ queryKey }) => {
    const [_key, tableParams] = queryKey;

    return listContent({
      where: { ...tableParams.filters, type: "VIDEO" },
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      populate: "image tags author",
    });
  };

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["videos", tableParams],
    queryFn: fetchVideos,
  });

  const showVideoModal = (video?) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(ContentModal, { content: video, type: "VIDEO" }).then(() =>
      refetch()
    );
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters: any,
    sorter
  ) => {
    console.log({ pagination, filters, sorter });

    if (filters.topics) {
      filters.topics = { $in: filters.topics };
    } else {
      filters.topics = undefined;
    }

    if (filters.categories) {
      filters.categories = { $in: filters.categories };
    } else {
      filters.categories = undefined;
    }

    if (!filters.langage) {
      filters.language = undefined;
    }
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    refetch();
  };

  const handleMenuClick = (e, row) => {
    if (e.key === "1") {
      showVideoModal(row);
    }
  };

  console.log(data?.data);

  const columns = [
    {
      title: t("IMAGE", "Image"),
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <img
            style={{
              width: "60px",
              margin: "5px",
              borderRadius: "5px",
            }}
            src={image?.thumbnailUrl}
          />
        );
      },
    },
    {
      title: t("TITLE", "Title"),
      dataIndex: "locales",
      key: "title",
      render: (locales, row) => {
        const { isFeatured } = row;
        console.log(i18n.language);
        const locale =
          locales[i18n.language.split("-")[0] || "en"] || locales.en;
        return (
          <div>
            {isFeatured ? (
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                color="orange"
                style={{ width: "40px", margin: "0px" }}
              />
            ) : (
              <div></div>
            )}
            <Link to={`/dashboard/videos/${row._id}`}>{locale.title}</Link>
          </div>
        );
      },
    },
    {
      title: t("LEVEL", "Level"),
      dataIndex: "level",
      key: "level",
    },
    {
      title: t("AUTHOR", "Author"),
      dataIndex: "author",
      key: "author",
      render: (author) => {
        return <div>{`${author.firstName} ${author.lastName}`}</div>;
      },
    },
    {
      title: t("DURATION", "Duration"),
      dataIndex: "duration",
      key: "duration",
      render: (duration) => {
        return <div>{dayjs.duration(duration).humanize()}</div>;
      },
    },
    {
      title: t("LANGUAGES", "Languages"),
      dataIndex: "locales",
      key: "language",
      render: (locales) => {
        return (
          <div>
            {Object.keys(locales).map((l) => {
              return <Tag>{l}</Tag>;
            })}
          </div>
        );
      },
      filters: [
        {
          text: t("en"),
          value: "en",
        },
        {
          text: t("fr"),
          value: "fr",
        },
      ],
    },
    {
      title: t("STATUS", "Status"),
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(e, row);
              },
            }}
          >
            <a>
              <FontAwesomeIcon icon={faBars} size="xl" color="#5B9CD1" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1>{t("VIDEOS", "Videos")}</h1>
          <Button
            type="primary"
            onClick={() => {
              showVideoModal();
            }}
          >
            {t("CREATE_VIDEO", "Create Video")}
          </Button>
        </div>
      </div>
      <Table
        dataSource={data?.data}
        columns={columns}
        loading={isPending}
        pagination={{ ...tableParams.pagination, total: data?.count }}
        onChange={handleTableChange}
      />
      ;
    </div>
  );
};

export default Videos;
