import React, { useState } from "react";
import { Form, InputNumber, Select, Input, DatePicker, TimePicker } from "antd";
import { IEventMeta } from "src/Types/Database.types";

export const EventMeta = () => {
  return (
    <>
      <h2>Event Meta</h2>
      <Form.Item<IEventMeta>
        label="Max attendees"
        name="meta.maximumAttendees"
        rules={[{ required: true, message: "Required" }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Zoom Link"
        name="meta.meetingLink"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Level"
        name="meta.level"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          placeholder="Select an Level"
          optionFilterProp="children"
          options={[
            {
              label: "Beginner",
              value: "BEGINNER",
            },
            {
              label: "Intermediate",
              value: "INTERMEDIATE",
            },
            {
              label: "Advanced",
              value: "ADVANCED",
            },
          ]}
        />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Date"
        name="meta.date"
        rules={[{ required: true, message: "Required" }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Start Time"
        name="meta.startTime"
        rules={[{ required: true, message: "Required" }]}
      >
        <TimePicker showSecond={false} format="HH:mm" minuteStep={15} />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="End Time"
        name="meta.endTime"
        rules={[{ required: true, message: "Required" }]}
      >
        <TimePicker showSecond={false} format="HH:mm" minuteStep={15} />
      </Form.Item>
    </>
  );
};
