import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Checkbox,
  Popover,
  Select,
  Button,
  Collapse,
} from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useExpertHook, {
  createExpert,
  updateExpert,
} from "src/Services/Api/expert.api";
import ReactQuill from "react-quill";
import { locales } from "src/constants";
import { DeleteFilled } from "@ant-design/icons";
import { ImageUpload } from "src/Components/ContentForm/image";
import { Image } from "src/Types/Database.types";

interface IExpert {
  email: string;
  firstName: string;
  lastName: string;
  qualifications: string[];
  resources: string[];
  clients: string[];
  isFeatured: boolean;
  insurance: boolean;
}

export default NiceModal.create(({ expert }: { expert?: any }) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState();
  const [selectedLocales, setSelectedLocales] = useState(["en", "el"]);
  const [imageId, setImageId] = useState((expert?.image as Image)?._id);

  const [qualifications, setQualifications] = useState(
    expert?.qualifications || []
  );
  const [resources, setResources] = useState(expert?.resources || []);
  const [clients, setClients] = useState(expert?.clients || []);

  const [form] = Form.useForm();

  useEffect(() => {
    if (expert) {
      setSelectedLocales(Object.keys(expert.locales).map((l) => l));
    }
  }, []);

  const initialLocales = {};
  if (expert) {
    Object.keys(expert?.locales).forEach((l) => {
      initialLocales[`locales.${l}.expertise`] = expert.locales[l].expertise;
      initialLocales[`locales.${l}.description`] =
        expert.locales[l].description;
    });
  }

  const handleSelectLocale = (e) => {
    setSelectedLocale(e);
  };

  return (
    <Modal
      title={expert ? "Edit Expert" : "Create Expert"}
      onOk={async () => {
        let newExpert = form.getFieldsValue();

        setLoading(true);
        if (expert) {
          newExpert = { ...expert, newExpert };
        }

        console.log({ newExpert });

        try {
          const result = await form.validateFields();
        } catch (err) {
          console.log(err);
          setLoading(false);
          return;
        }

        Object.keys(newExpert).forEach((key) => {
          if (key.split(".").length) {
            const [, locale, att] = key.split(".");

            if (!locale) {
              return;
            }

            if (!newExpert.locales) {
              newExpert.locales = {};
            }

            let thisLocale = newExpert.locales[locale];

            if (!thisLocale) {
              newExpert.locales[locale] = {};
            }

            newExpert.locales[locale][att] = newExpert[key];
          }

          delete newExpert[key];
        });

        newExpert.qualifications = qualifications;
        newExpert.resources = resources;
        newExpert.clients = clients;
        newExpert.image = imageId;

        console.log({ newExpert });

        if (expert) {
          await updateExpert({
            ...expert,
            ...newExpert,
          });
          setLoading(false);
          modal.resolve();
          modal.hide();
        } else {
          await createExpert(newExpert);
          setLoading(false);
          modal.resolve();
          modal.hide();
        }
      }}
      confirmLoading={loading}
      open={modal.visible}
      onCancel={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{
          ...initialLocales,
          firstName: expert?.firstName,
          lastName: expert?.lastName,
          insurance: expert?.insurance,
          isFeatured: expert?.isFeatured,
        }}
        autoComplete="off"
      >
        <div style={{ margin: "20px" }}>
          <Form.Item<IExpert>
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<IExpert>
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
          {!expert && (
            <Form.Item<IExpert>
              label="Email"
              name="email"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          )}

          {selectedLocales?.map((locale) => {
            return (
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                items={[
                  {
                    key: "1",
                    label: locale,
                    children: (
                      <>
                        <Form.Item
                          label="Description"
                          name={`locales.${locale}.description`}
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <ReactQuill
                            onChange={(e) => {
                              console.log({ e });
                            }}
                            style={{ borderRadius: "5px" }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Expertise"
                          name={`locales.${locale}.expertise`}
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input
                            onChange={(e) => {
                              console.log({ e });
                            }}
                            style={{ borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </>
                    ),
                    // style: panelStyle,
                  },
                ]}
              />
            );
          })}
        </div>

        <Popover
          content={
            <div>
              <Select
                style={{ minWidth: "200px" }}
                options={locales.filter(
                  (l) => !selectedLocales.includes(l.value)
                )}
                onChange={handleSelectLocale}
              />

              <Button
                style={{ margin: "10px" }}
                type="primary"
                onClick={() => {
                  if (selectedLocale) {
                    setSelectedLocales([...selectedLocales, selectedLocale!]);
                  }
                }}
              >
                Add
              </Button>
              {/* <a onClick={hide}>Close</a> */}
            </div>
          }
          title="Click title"
          trigger="click"
          // open={clicked}
          // onOpenChange={handleClickChange}
        >
          <Button style={{ marginBottom: "20px", marginLeft: "20px" }}>
            Add Locale
          </Button>
        </Popover>

        <Form.Item<IExpert> label="Qualifications" name="qualifications">
          {qualifications?.map((qualification, index) => {
            return (
              <div
                style={{ display: "flex", height: "44px", marginTop: "5px" }}
              >
                <Input
                  style={{ marginBottom: "10px", height: "44px" }}
                  value={qualification}
                  onChange={({ target }) => {
                    const arr = [...qualifications];
                    arr[index] = target.value;
                    setQualifications(arr);
                  }}
                />
                <Button
                  danger
                  type="primary"
                  style={{ height: "44px", marginLeft: "10px" }}
                  onClick={() => {
                    const copy = [...qualifications];
                    console.log({ copy, index });
                    copy.splice(index, 1);
                    console.log({ copy });
                    setQualifications(copy);
                  }}
                >
                  <DeleteFilled />
                </Button>
              </div>
            );
          })}
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            // disabled={!qualifications[qualifications.length - 1]?.length}
            onClick={() => {
              setQualifications([...qualifications, ""]);
            }}
          >
            Add Qualification
          </Button>
        </div>

        {/* <Form.Item<IExpert> label="Resources" name="resources">
          {resources?.map((resource, index) => {
            return (
              <div
                style={{ display: "flex", height: "44px", marginTop: "5px" }}
              >
                <Input
                  style={{ marginBottom: "10px", height: "44px" }}
                  value={resource}
                  onChange={({ target }) => {
                    const arr = [...resources];
                    arr[index] = target.value;
                    setResources(arr);
                  }}
                />
                <Button
                  danger
                  type="primary"
                  style={{ height: "44px", marginLeft: "10px" }}
                  onClick={() => {
                    const copy = [...resources];
                    console.log({ copy, index });
                    copy.splice(index, 1);
                    console.log({ copy });
                    setResources(copy);
                  }}
                >
                  <DeleteFilled />
                </Button>
              </div>
            );
          })}
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            // disabled={!qualifications[qualifications.length - 1]?.length}
            onClick={() => {
              setResources([...resources, ""]);
            }}
          >
            Add Resource
          </Button>
        </div> */}

        {/* <Form.Item<IExpert> label="Clients" name="clients">
          {clients?.map((client, index) => {
            return (
              <div
                style={{ display: "flex", height: "44px", marginTop: "5px" }}
              >
                <Input
                  style={{ marginBottom: "10px", height: "44px" }}
                  value={client}
                  onChange={({ target }) => {
                    const arr = [...clients];
                    arr[index] = target.value;
                    setClients(arr);
                  }}
                />
                <Button
                  danger
                  type="primary"
                  style={{ height: "44px", marginLeft: "10px" }}
                  onClick={() => {
                    const copy = [...clients];
                    console.log({ copy, index });
                    copy.splice(index, 1);
                    console.log({ copy });
                    setClients(copy);
                  }}
                >
                  <DeleteFilled />
                </Button>
              </div>
            );
          })}
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            // disabled={!qualifications[qualifications.length - 1]?.length}
            onClick={() => {
              setClients([...clients, ""]);
            }}
          >
            Add Client
          </Button>
        </div> */}

        <ImageUpload
          content={expert}
          imageId={imageId}
          setImageId={setImageId}
        />

        <Form.Item<IExpert>
          label="Has Insurance?"
          name="insurance"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item<IExpert>
          label="Is Featured?"
          name="isFeatured"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
});
