import React, { useState, useEffect } from "react";
import { Form, Collapse, Input, Popover, Select, Button } from "antd";
import ReactQuill from "react-quill";
import { locales } from "src/constants";

export const Locales = (content) => {
  const [selectedLocale, setSelectedLocale] = useState();
  const [selectedLocales, setSelectedLocales] = useState(["en", "el"]);

  const handleSelectLocale = (e) => {
    setSelectedLocale(e);
  };

  useEffect(() => {
    if (content && content.locales) {
      setSelectedLocales(Object.keys(content.locales).map((l) => l));
    }
  }, []);

  return (
    <>
      <div style={{ margin: "20px" }}>
        {selectedLocales.map((locale) => {
          return (
            <Collapse
              bordered={false}
              // defaultActiveKey={["1"]}
              items={[
                {
                  key: "1",
                  label: locale,
                  children: (
                    <>
                      <Form.Item
                        label="Title"
                        name={`locales.${locale}.title`}
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Description"
                        name={`locales.${locale}.description`}
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <ReactQuill style={{ borderRadius: "5px" }} />
                      </Form.Item>
                    </>
                  ),
                },
              ]}
            />
          );
        })}
      </div>

      <Popover
        content={
          <div>
            <Select
              style={{ minWidth: "200px" }}
              options={locales.filter(
                (l) => !selectedLocales.includes(l.value)
              )}
              onChange={handleSelectLocale}
            />

            <Button
              style={{ margin: "10px" }}
              type="primary"
              onClick={() => {
                if (selectedLocale) {
                  setSelectedLocales([...selectedLocales, selectedLocale!]);
                }
              }}
            >
              Add
            </Button>
          </div>
        }
        title="Click title"
        trigger="click"
      >
        <Button style={{ marginBottom: "20px", marginLeft: "20px" }}>
          Add Locale
        </Button>
      </Popover>
    </>
  );
};
