import React, { useState, useEffect } from "react";
import { Alert, Button, Input, Rate } from "antd";
import { setUserPassword } from "src/Services/Api/user.api";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import { createReview } from "src/Services/Api/review.api";

export const Feedback = () => {
  const [rating, setRating] = useState<number>();
  const [review, setReview] = useState("");
  const [errorText, setErrorText] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { event_id } = useParams();

  const handleSubmit = async () => {
    console.log({ rating, review });
    setErrorText("");
    if (!event_id) {
      return;
    }
    if (!rating) {
      setErrorText("Please set a rating");
      return;
    }

    if (!review) {
      setErrorText("Please write a review");
      return;
    }
    await createReview({
      contentId: event_id,
      rating,
      review,
    });
  };

  return (
    <div
      style={{
        marginTop: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/logo.png" style={{ height: "60px", marginBottom: "20px" }} />
        <h1>
          {t("THANKS_FOR_ATTENDING_EVENT", "Thanks for attending this event")}
        </h1>

        <p>
          {t(
            "PLEASE_PROVIDE_FEEDBACK",
            "We would appreciate it if you could take the time to provide feedback on this event."
          )}
        </p>

        <div style={{ marginTop: "20px" }}>
          {t("RATE_EVENT", "Rate Event:")}
        </div>
        <Rate
          style={{ margin: "20px" }}
          onChange={(rating) => {
            setRating(rating);
          }}
        />

        <TextArea
          rows={4}
          onChange={(e) => {
            setReview(e.target.value);
          }}
        />
      </div>
      {errorText && (
        <Alert style={{ marginTop: "20px" }} message={errorText} type="error" />
      )}
      <Button
        type="primary"
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
      >
        {t("SEND_FEEDBACK", "Send Feedback")}
      </Button>
    </div>
  );
};
