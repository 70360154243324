import { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";

export const createReview = async ({
  contentId,
  rating,
  review,
}: {
  contentId: string;
  rating: number;
  review: string;
}) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const { data } = await axios.post(
    `${baseApiUrl}/reviews`,
    { contentId, rating, review },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return data;
};
