import React from "react";
import { useTranslation } from "react-i18next";
import { ChartCard } from "./ChartCard";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { listMetrics } from "src/Services/Api/metric";

export const EventRegistration = ({ user }) => {
  const { t } = useTranslation();

  const fetchEventsRegistered = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "event.register" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      populate: ["asset"],
      limit: 0,
    });
  };
  const eventsRegistered = useQuery({
    queryKey: ["events", "event.register"],
    queryFn: fetchEventsRegistered,
  });

  const eventsRegisteredData: any[] = [];

  [6, 5, 4, 3, 2, 1, 0].forEach((offset) => {
    const day = dayjs().startOf("day").subtract(offset, "days");

    eventsRegisteredData.push({ date: day.toDate(), value: 0 });

    eventsRegistered.data?.data.forEach((a) => {
      if (dayjs(a.createdAt).isSame(day, "day")) {
        if (a.type === "event.register")
          eventsRegisteredData.find(
            (d) => dayjs(d.date).format() === day.format()
          ).value += 1;
      }
    });
  });

  return (
    <ChartCard
      data={eventsRegisteredData}
      title={t("NUMBER_OF_REGISTRATIONS", "Number of Registrations")}
    />
  );
};
