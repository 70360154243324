import React from "react";
import { Card } from "antd";
import { LineChart } from "@mui/x-charts/LineChart";

export const ChartCard = ({ data, title }) => {
  return (
    <Card bordered={true} style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "100" }}>{title}</h2>
      <LineChart
        sx={{
          "& .MuiLineElement-root": {
            strokeDasharray: "10 5",
            strokeWidth: 4,
          },
          "& .MuiAreaElement-series-Germany": {
            fill: "url('#myGradient')",
          },
        }}
        xAxis={[
          {
            dataKey: "date",
            scaleType: "time",
            tickMinStep: 3600 * 1000 * 24, // min step: 24h
          },
        ]}
        series={[
          {
            dataKey: "value",
            showMark: false,
            area: true,
            color: "#7BBCE5",
          },
        ]}
        dataset={data}
        //   width={500}
        height={300}
        width={500}
      />
      <defs>
        <linearGradient id="myGradient" gradientTransform="rotate(90)">
          <stop offset="5%" stopColor="gold" />
          <stop offset="95%" stopColor="red" />
        </linearGradient>
      </defs>
    </Card>
  );
};
