import React from "react";
import Timestamp from "../../Components/Timestamp/Timestamp";
import {
  Link,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
} from "@mui/material";
import { Badge, Button } from "antd";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    height: "300px",
    width: "700px",
  },
}));

const EventTooltip = ({ event, style = {} }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { _id, locales, image, meta } = event;
  const { startTime, endTime } = meta;

  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;

  return (
    <HtmlTooltip
      style={{ overflow: "hidden", height: "auto" }}
      title={
        <React.Fragment>
          <h2 style={{ fontWeight: 900 }}>{locale.title}</h2>
          <Timestamp startTime={startTime} endTime={endTime} />
          <div>
            <img
              src={image.thumbnailUrl}
              style={{
                minWidth: "100px",
                height: "100px",
                width: "100px",
                float: "left",
                borderRadius: "5px",
                marginRight: "10px",
                objectFit: "cover",
                // marginBottom: "10px",
              }}
            />
            <p
              style={{
                fontWeight: 300,
                fontSize: "12px",
                overflow: "hidden",
                height: "180px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: locale.description }}
              ></div>
            </p>
          </div>
        </React.Fragment>
      }
      placement="top"
      arrow
    >
      <Button
        type="link"
        style={{
          ...style,
          color: "#333",
          padding: 0,
          margin: 0,
          textWrap: "balance",
          textAlign: "left",
          wordWrap: "break-word",
          overflowWrap: "anywhere",
          whiteSpace: "pre-wrap",
        }}
        onClick={() => {
          navigate(`/dashboard/events/${_id}`);
        }}
      >
        {locale.title}
      </Button>
    </HtmlTooltip>
  );
};

export default EventTooltip;
