import React from "react";
import { NumberCard } from "./NumberCard";
import { listMetrics } from "src/Services/Api/metric";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const ArticlesLiked = ({ user }) => {
  const { t } = useTranslation();
  const fetchArticlesLiked = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "article.like" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      populate: ["asset"],
      limit: 0,
    });
  };
  const articlesLiked = useQuery({
    queryKey: ["metrics", "article.like"],
    queryFn: fetchArticlesLiked,
  });

  return (
    <NumberCard
      loading={articlesLiked.isPending}
      count={articlesLiked.data?.count}
      title={t("ARTICLES_LIKED", "Articles Liked")}
    />
  );
};
