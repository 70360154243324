import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getResource } from "src/Services/Api/resource.get";
import { Button, Skeleton, Spin, Tabs } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createMetric } from "src/Services/Api/metric";
import { useTranslation } from "react-i18next";
import { Image } from "src/Components/Image/Image";
import UserTable from "src/Components/UserTable";
import { listUsers } from "src/Services/Api/user.api";
import EventsList from "./EventsList";
import EventsTable from "src/Components/EventsUpcomingTable";
import dayjs from "dayjs";
import { listEvents } from "src/Services/Api/event.api";
import NiceModal from "@ebay/nice-modal-react";
import UserModal from "../../Modals/user.modal";

const Company = () => {
  const { t, i18n } = useTranslation();
  const { company_id: id } = useParams();

  const fetchCompany = async ({ queryKey }) => {
    const [key, { id }] = queryKey;

    return getResource(
      { queryKey },
      {
        populate: "image author",
      }
    );
  };

  const fetchEvents = async ({ queryKey }) => {
    const [_key, tableParams] = queryKey;

    const where = {
      ...tableParams.filters,
      date: { $gt: dayjs().format() },
      availableFor: { $in: [id] },
    };

    // if (user) {
    //   where.availableFor = { $in: user.company._id };
    // }

    return listEvents({
      where,
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      populate: "image topics categories author",
    });
  };

  const company = useQuery({
    queryKey: ["companies", { id }],
    queryFn: fetchCompany,
  });

  const showUserModal = () => {
    NiceModal.show(UserModal, { companyId: id });
  };

  if (company.error) {
    return (
      <div>
        Return to <a href="/dashboard/companies">Companies</a>
      </div>
    );
  }

  if (company.isPending) {
    return <Spin />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ margin: "20px", width: "100%" }}>
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 900,
            color: "#000",
            margin: "20px",
            marginLeft: "40px",
          }}
        >
          {company.data.data.name}
        </h1>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Users",
              children: (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1>{t("USERS", "Users")}</h1>
                    <Button
                      type="primary"
                      onClick={() => {
                        showUserModal();
                      }}
                    >
                      {t("CREATE_USER", "Create User")}
                    </Button>
                  </div>
                  <UserTable
                    listUsers={() =>
                      listUsers({ where: { company: { $eq: id } } })
                    }
                  />
                </div>
              ),
            },
            {
              key: "2",
              label: "Events",
              children: (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1>{t("EVENTS", "Events")}</h1>
                    <Button
                      type="primary"
                      onClick={() => {
                        // showArticleModal();
                      }}
                    >
                      {t("ADD_EVENT_TO_COMPANY", "Add Event to Company")}
                    </Button>
                  </div>
                  <EventsTable queryFn={fetchEvents} showModal={() => {}} />;
                </div>
              ),
            },
            {
              key: "3",
              label: "Articles",
              children: (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1>{t("EVENTS", "Events")}</h1>
                    <Button
                      type="primary"
                      onClick={() => {
                        // showArticleModal();
                      }}
                    >
                      {t("ADD_EVENT_TO_COMPANY", "Add Event to Company")}
                    </Button>
                  </div>
                  <EventsTable queryFn={fetchEvents} showModal={() => {}} />;
                </div>
              ),
            },
            {
              key: "4",
              label: "Videos",
              children: (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1>{t("EVENTS", "Events")}</h1>
                    <Button
                      type="primary"
                      onClick={() => {
                        // showArticleModal();
                      }}
                    >
                      {t("ADD_EVENT_TO_COMPANY", "Add Event to Company")}
                    </Button>
                  </div>
                  <EventsTable queryFn={fetchEvents} showModal={() => {}} />;
                </div>
              ),
            },
          ]}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};
export default Company;
