import React from "react";
import { Card, Tag } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import relativeTime from "dayjs/plugin/relativeTime";
import Timestamp from "../Timestamp/Timestamp";

dayjs.extend(relativeTime);

const Event = ({ event }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { _id, image, date, startTime, endTime, category, tags, locales } =
    event;
  const url = image.url;

  const theDate = dayjs(date);

  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;

  return (
    <Card
      hoverable
      style={{
        width: 240,
        textWrap: "pretty",
        margin: "10px",
      }}
      cover={
        <img
          alt="example"
          src={url}
          style={{ height: "140px", objectFit: "cover" }}
        />
      }
      onClick={() => {
        navigate(`/dashboard/events/${_id}`);
      }}
    >
      <div style={{ position: "absolute", top: 0, left: 0, margin: "10px" }}>
        <Tag color="#fff" style={{ color: "#000", opacity: "0.8" }}>
          {t(category.name)}
        </Tag>

        {tags.map((topic) => {
          return (
            <Tag color="#fff" style={{ color: "#000", opacity: "0.8" }}>
              {t(topic.name)}
            </Tag>
          );
        })}
      </div>

      <div
        style={{
          position: "absolute",
          top: "77px",
          right: "5px",
          padding: "6px",
          paddingTop: "2px",
          paddingBottom: "2px",
          backgroundColor: "#fff",
          opacity: "0.9",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flexDirection: "column",
          width: "50px",
        }}
      >
        <h2 style={{ fontSize: "16px", margin: 0 }}>{theDate.format("MMM")}</h2>
        <h1
          style={{
            fontWeight: 900,
            fontSize: "24px",
            // margin: 0,
            marginTop: "-10px",
            paddingBottom: "0px",
          }}
        >
          {theDate.format("D")}
        </h1>
      </div>

      <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          height: "65px",
          textOverflow: "ellipsis",
        }}
      >
        {locale.title}
      </div>
      <div style={{ color: "#999", marginTop: "5px" }}>
        <Timestamp color={"#999"} startTime={startTime} endTime={endTime} />
      </div>
    </Card>
  );
};

export default Event;
