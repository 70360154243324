import React, { useEffect, useState } from "react";
import { PieCard } from "./PieCard";
import { useTranslation } from "react-i18next";
import { listMetrics } from "src/Services/Api/metric";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { listCategories } from "src/Services/Api/category.api";
import { Spin } from "antd";

export const VideosReadByCategory = ({ user }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  const fetchVideos = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "video.read" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      populate: ["asset"],
      limit: 0,
    });
  };

  const articles = useQuery({
    queryKey: ["metrics", "video.read", "category"],
    queryFn: fetchVideos,
  });

  const categories = useQuery({
    queryKey: ["categories"],
    queryFn: () => listCategories({ limit: 1000 }),
  });

  const getGraphData = () => {
    if (!loading) {
      return;
    }
    console.log({ categories, articles });
    if (!categories.data?.data || !articles.data?.data) {
      return;
    }
    const d = articles.data?.data.reduce((acc, metric) => {
      if (!acc[metric.asset?.category]) {
        acc[metric.asset?.category] = 0;
      }
      acc[metric.asset?.category] += 1;
      return acc;
    }, {});
    constructGraphData(d);
  };

  const constructGraphData = (data) => {
    console.log({ data });
    if (!data) return;
    const d = Object.keys(data).map((key) => {
      const category = categories.data?.data.find((c) => c._id === key);

      const categoryName = category?.name || "";

      return {
        id: key,
        value: data[key],
        label: t(categoryName),
      };
    });
    setData(d);
    setLoading(false);
  };

  useEffect(() => getGraphData, [articles, categories]);

  return (
    <PieCard
      loading={loading}
      title={t("VIDEOS_READ_BY_CATEGORY", "Videos read by Category")}
      data={data}
    />
  );
};
