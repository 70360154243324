import React from "react";
import { Card, Spin } from "antd";
import CountUp from "react-countup";

export const NumberCard = ({ count, title, loading = false }) => {
  return (
    <Card bordered={true} style={{ margin: "20px", minWidth: "300px" }}>
      <h2 style={{ fontWeight: "100" }}>{title}</h2>
      <div style={{ fontWeight: 600, fontSize: "44px", color: "#598BBC" }}>
        {loading ? <Spin /> : <CountUp start={0} end={count} duration={2} />}
      </div>
    </Card>
  );
};
