export const convertLocales = (content) => {
  const localisedContent = { ...content };
  Object.keys(localisedContent).forEach((key) => {
    if (key.split(".").length) {
      const [type, locale, att] = key.split(".");

      if (!locale || type !== "locales") {
        return;
      }

      if (!localisedContent.locales) {
        localisedContent.locales = {};
      }

      let thisLocale = localisedContent.locales[locale];

      if (!thisLocale) {
        localisedContent.locales[locale] = {};
      }

      localisedContent.locales[locale][att] = localisedContent[key];
    }

    delete localisedContent[key];
  });

  return localisedContent;
};

export const convertMeta = (content) => {
  const metaContent = { ...content };
  Object.keys(metaContent).forEach((key) => {
    if (key.split(".").length) {
      const [type, att] = key.split(".");

      if (!att || type !== "meta") {
        return;
      }

      if (!metaContent.meta) {
        metaContent.meta = {};
      }

      metaContent.meta[att] = metaContent[key];
    }

    delete metaContent[key];
  });

  return metaContent;
};
