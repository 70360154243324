import { fetchAuthSession, getCurrentUser, signUp } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";
import { v4 } from "uuid";
import qs from "qs";
import { defaultOptions, generatePassword } from "src/Utils/passwordGenerator";

export const listUsers = async (searchParams?) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const { data } = await axios.get(`${baseApiUrl}/users${queryString}`, {
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export const updateUser = async (user) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const { username } = await getCurrentUser();
  const { data } = await axios.patch(`${baseApiUrl}/users/${username}`, user, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

export const getUser = async ({
  id,
  searchParams,
}: {
  id: string;
  searchParams?: any;
}) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/users/${id}${queryString}`, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

export const setUserPassword = async ({ confirmationGuid, password }) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const { data } = await axios.put(
    `${baseApiUrl}/users/accounts/${confirmationGuid}`,
    {
      password,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  console.log({ data });
};

export const resetPassword = async ({ email }) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const { data } = await axios.post(
    `${baseApiUrl}/users/reset-password`,
    {
      email,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  console.log({ data });
};
export const createUserInCompany = async ({
  email,
  firstName,
  lastName,
  companyId,
}) => {
  const password = await generatePassword(defaultOptions);
  const confirmationGuid = v4();

  console.log({ password });
  const { isSignUpComplete, userId, nextStep } = await signUp({
    username: email,
    password,
    options: {
      userAttributes: {
        email,
        given_name: firstName,
        family_name: lastName,
      },

      // optional
      autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
    },
  });

  const { data } = await axios.post(`${baseApiUrl}/users/`, {
    userId,
    companyId,
    firstName,
    lastName,
    email,
    confirmationGuid,
  });
};

const useUser = () => {
  const createUser = async (userId: string, companyName: string) => {
    const { data } = await axios.post(`${baseApiUrl}/users/`, {
      userId,
      companyName,
    });
  };

  const registerUserForEvent = async (eventId, emailAddress) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { username } = await getCurrentUser();
    const { data } = await axios.post(
      `${baseApiUrl}/users/${username}/events`,
      { eventId, emailAddress },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  const unregisterUserForEvent = async (eventId) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { username } = await getCurrentUser();
    const { data } = await axios.delete(
      `${baseApiUrl}/users/${username}/events/${eventId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data;
  };

  return {
    users: [],
    listUsers,
    updateUser,
    getUser,
    createUser,
    registerUserForEvent,
    unregisterUserForEvent,
  };
};

export default useUser;
