import React, { useState } from "react";
import { Form, InputNumber, Select, Input, DatePicker, TimePicker } from "antd";
import { IEventMeta } from "src/Types/Database.types";

export const VideoMeta = () => {
  return (
    <>
      <h2>Event Meta</h2>

      <Form.Item<IEventMeta>
        label="Zoom Link"
        name="meta.youtubeUrl"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Language"
        name="meta.language"
        tooltip="The language the video is spoken in"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Type"
        name="meta.type"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          placeholder="Select an Type"
          optionFilterProp="children"
          options={[
            {
              label: "Webinar",
              value: "WEBINAR",
            },
            {
              label: "Class",
              value: "CLASS",
            },
            {
              label: "Podcast",
              value: "PODCAST",
            },
            {
              label: "Short",
              value: "SHORT",
            },
            {
              label: "Event",
              value: "EVENT",
            },
          ]}
        />
      </Form.Item>

      <Form.Item<IEventMeta>
        label="Event"
        name="meta.event"
        tooltip="ID of linked event, if type is Event"
      >
        <Input />
      </Form.Item>
    </>
  );
};
