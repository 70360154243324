import { PieChart } from "@mui/x-charts/PieChart";
import { Card, Spin } from "antd";
import React from "react";

export const PieCard = ({ data, title, loading }) => {
  return (
    <Card bordered={true} style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "100" }}>{title}</h2>
      {loading ? (
        <Spin />
      ) : (
        <PieChart
          series={[
            {
              data,
              innerRadius: 30,
              outerRadius: 100,
              paddingAngle: 5,
              cornerRadius: 5,
              startAngle: -90,
              endAngle: 270,
              cx: 150,
              cy: 150,
            },
          ]}
          width={690}
          height={260}
        />
      )}
    </Card>
  );
};
