import React, { useState } from "react";
import { Modal, Form } from "antd";
import { Document } from "mongoose";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { IContent, IUserRecord, Image } from "src/Types/Database.types";
import { useTranslation } from "react-i18next";
import { createContent, updateContent } from "src/Services/Api/content.api";
import { ContentForm } from "src/Components/ContentForm";
import { EventMeta } from "src/Components/ContentForm/eventMeta";
import dayjs from "dayjs";
import { convertLocales, convertMeta } from "src/Utils/modelHelper";
import { VideoMeta } from "src/Components/ContentForm/videoMeta";

export default NiceModal.create(
  ({
    content,
    author,
    type,
  }: {
    content?: IContent;
    author: IUserRecord;
    type: string;
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [loading, setLoading] = useState(false);
    const [imageId, setImageId] = useState((content?.image as Image)?._id);

    const { t } = useTranslation();
    const [form] = Form.useForm();

    // Convert to Dot Notation
    const initialLocales = {};
    const initialMeta = {};
    if (content) {
      Object.keys(content?.locales).forEach((l) => {
        initialLocales[`locales.${l}.title`] = content.locales[l].title;
        initialLocales[`locales.${l}.description`] =
          content.locales[l].description;
      });
      Object.keys(content?.meta).forEach((m) => {
        if (m === "date" || m === "startTime" || m === "endTime") {
          initialMeta[`meta.${m}`] = dayjs(content.meta[m]);
        } else {
          initialMeta[`meta.${m}`] = content.meta[m];
        }
      });
    }

    const getInitialValues = () => {
      const initialValues = {
        ...initialLocales,
        ...initialMeta,
        status: content?.status,
        title: content?.title,
        author: (content?.author as Document)?._id,

        tags: content?.tags.map((t) => (t as Document)._id),
        category: (content?.category as Document)?._id,

        image: (content?.image as Document)?._id,
        isFeatured: content?.isFeatured,
      };

      return initialValues;
    };

    const handleSave = async () => {
      setLoading(true);

      const formValues = form.getFieldsValue();
      console.log({ formValues });

      try {
        const result = await form.validateFields();
      } catch (err) {
        console.log(err);
        // Ignore error
      }

      // const formValues = form.getFieldsValue();
      const newContent = { ...getInitialValues(), ...formValues };
      newContent.image = imageId;
      newContent.type = type;

      // Get meta data
      newContent.meta = {};

      Object.keys(newContent).forEach((key) => {
        if (
          key.split["."] &&
          key.split["."].length &&
          key.split["."][1] === "meta"
        ) {
          newContent.meta[key.split(".")[1]] = newContent[key];
        }
      });

      const metaContent = convertMeta(newContent);
      const localisedContent = convertLocales(metaContent);

      if (author) {
        localisedContent.author = author._id;
        localisedContent.availableFor = [author.company._id];
      }
      let result;
      try {
        if (!content) {
          result = await createContent(localisedContent);
        } else {
          result = await updateContent({
            ...localisedContent,
            _id: content._id,
          });
        }

        modal.resolve(result);
        modal.hide();
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    return (
      <Modal
        title={content ? "Edit" : "Create"}
        onOk={handleSave}
        confirmLoading={loading}
        open={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={getInitialValues()}
          autoComplete="off"
        >
          <ContentForm
            author={author}
            content={content}
            imageId={imageId}
            setImageId={setImageId}
          />

          {type === "EVENT" && <EventMeta />}
          {type === "VIDEO" && <VideoMeta />}
        </Form>
      </Modal>
    );
  }
);
