import React from "react";
import { Form, Select, Checkbox, Input } from "antd";
import { IContent, IUserRecord, Image } from "src/Types/Database.types";
import { useQuery } from "@tanstack/react-query";
import { listExperts } from "src/Services/Api/expert.api";
import { QueryResult } from "src/Types/QueryResult";
import { Locales } from "./locales";
import { listTopics } from "src/Services/Api/topic.api";
import { listCategories } from "src/Services/Api/category.api";
import { useTranslation } from "react-i18next";
import { ImageUpload } from "./image";

export const ContentForm = ({
  author,
  content,
  imageId,
  setImageId,
}: {
  author: IUserRecord;
  content: any;
  imageId: string;
  setImageId: Function;
}) => {
  const { t } = useTranslation();

  const experts: QueryResult = useQuery({
    queryKey: ["experts"],
    queryFn: () => listExperts({ limit: 1000 }),
  });

  const topics: QueryResult = useQuery({
    queryKey: ["topics"],
    queryFn: () => listTopics({ limit: 1000 }),
  });

  const categories: QueryResult = useQuery({
    queryKey: ["categories"],
    queryFn: () => listCategories({ limit: 1000 }),
  });

  return (
    <>
      <h2>Locales</h2>
      <Locales content={content} />
      <h2>General</h2>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<IContent>
        label="Status"
        name="status"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          placeholder="Select an Level"
          optionFilterProp="children"
          options={[
            {
              label: "Draft",
              value: "DRAFT",
            },
            {
              label: "Published",
              value: "PUBLISHED",
            },
            {
              label: "Archived",
              value: "ARCHIVED",
            },
          ]}
        />
      </Form.Item>

      {!author && (
        <Form.Item<IContent>
          label="Author"
          name="author"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            loading={experts.isPending}
            placeholder="Select an Expert"
            optionFilterProp="children"
            options={
              !experts.isPending
                ? experts.data.data.map((e) => {
                    return {
                      value: e._id,
                      label: `${e.firstName || ""} ${e.lastName || ""}`,
                    };
                  })
                : []
            }
          />
        </Form.Item>
      )}

      <Form.Item<IContent>
        label="Tags"
        name="tags"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          mode="multiple"
          loading={topics.isPending}
          placeholder="Select an Tag"
          optionFilterProp="children"
          options={
            !topics.isPending
              ? topics.data.data.map((topic) => {
                  return {
                    value: topic._id,
                    label: t(topic.name),
                  };
                })
              : []
          }
        />
      </Form.Item>

      <Form.Item<IContent>
        label="Category"
        name="category"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          loading={categories.isPending}
          placeholder="Select an Category"
          optionFilterProp="children"
          options={
            !categories.isPending
              ? categories.data.data.map((cat) => {
                  return {
                    value: cat._id,
                    label: t(cat.name),
                  };
                })
              : []
          }
        />
      </Form.Item>

      <ImageUpload
        content={content}
        imageId={imageId}
        setImageId={setImageId}
      />

      <Form.Item<IContent>
        label="Is Featured?"
        name="isFeatured"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
    </>
  );
};
