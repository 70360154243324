import React from "react";
import { NumberCard } from "./NumberCard";
import { listMetrics } from "src/Services/Api/metric";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const ActiveUsers = ({ user }) => {
  const { t } = useTranslation();

  const fetchActiveUsers = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "user.login" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
    });
  };

  const activeUsers = useQuery({
    queryKey: ["metrics", "user.login"],
    queryFn: fetchActiveUsers,
  });

  return (
    <NumberCard
      loading={activeUsers.isPending}
      count={
        activeUsers.data?.data.reduce((acc, cur) => {
          if (!acc.includes(cur.userId)) {
            acc.push(cur.userId);
          }
          return acc;
        }, []).length
      }
      title={t("ACTIVE_USERS", "Active Users")}
    />
  );
};
