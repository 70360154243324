import React from "react";
import { NumberCard } from "./NumberCard";
import { useQuery } from "@tanstack/react-query";
import { listMetrics } from "src/Services/Api/metric";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const EventsRead = ({ user }) => {
  const { t } = useTranslation();
  const fetchEventsRead = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "event.read" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
    });
  };

  const eventsRead = useQuery({
    queryKey: ["events", "event.read"],
    queryFn: fetchEventsRead,
  });

  return (
    <NumberCard
      loading={eventsRead.isPending}
      count={eventsRead.data?.count}
      title={t("EVENTS_READ", "Events Read")}
    />
  );
};
