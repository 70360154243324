import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Rate, Tag } from "antd";
import type { MenuProps, Breakpoint } from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const columns = (t, i18n) => {
  return [
    {
      title: t("IMAGE", "Image"),
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <img
            style={{
              width: "60px",
              margin: "5px",
              borderRadius: "5px",
            }}
            src={image.thumbnailUrl}
          />
        );
      },
    },
    {
      title: t("TITLE", "Title"),
      dataIndex: "locales",
      key: "title",
      render: (locales, row) => {
        const locale =
          locales[i18n.language.split("-")[0] || "en"] || locales.en;
        const { isFeatured } = row;
        return (
          <div>
            {isFeatured ? (
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                color="orange"
                style={{ width: "40px", margin: "0px" }}
              />
            ) : (
              <div></div>
            )}
            <Link to={`/dashboard/events/${row._id}`}>{locale.title}</Link>
          </div>
        );
      },
    },
    {
      title: t("AUTHOR", "Author"),
      dataIndex: "author",
      key: "author",
      render: (author) => {
        return <div>{`${author.firstName} ${author.lastName}`}</div>;
      },
    },
    {
      title: t("DATE", "Date"),
      dataIndex: "meta",
      key: "date",
      width: "1000",
      render: (meta) => {
        console.log({ meta });
        const { date, startTime, endTime } = meta;
        return (
          <div>
            <div>{dayjs(date).format("MMM Do, YYYY")}</div>
            <div style={{ color: "#999" }}>
              {dayjs(startTime).format("HH:mm")} -
              {dayjs(endTime).format("HH:mm")}
            </div>
          </div>
        );
      },
    },
    {
      title: t("REGISTERED_ATTENDEES", "Registered Attendees"),
      dataIndex: "meta",
      key: "attendees",
      render: (meta) => {
        return <>{_.uniq(meta.attendees).length}</>;
      },
    },
    {
      title: t("AVERAGE_REVIEWS", "Average Reviews"),
      dataIndex: "reviews",
      key: "reviews",
      render: (reviews) => {
        let total = 0;
        let count = reviews?.length;
        reviews?.forEach((review) => (total += review.rating));
        const average = total / count;
        return <Rate disabled defaultValue={average} />;
      },
    },
  ];
};
