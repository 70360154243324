import { useQuery } from "@tanstack/react-query";
import { Card, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listMetrics } from "src/Services/Api/metric";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
  },
];

export const TopEvents = ({ user }) => {
  const { t } = useTranslation();

  const [data, setData] = useState();

  const fetchEventsRegistered = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "event.register" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      populate: ["asset"],
      limit: 0,
    });
  };

  const eventsRegistered = useQuery({
    queryKey: ["events", "event.register"],
    queryFn: fetchEventsRegistered,
  });

  useEffect(() => {
    if (!eventsRegistered.data?.data) return;
    const data = [];

    console.log(eventsRegistered.data?.data);

    const aggregatedCounts = eventsRegistered.data?.data.reduce((acc, cur) => {
      console.log({ acc, cur });
      if (!acc[cur.asset._id]) {
        acc[cur.asset._id] = {
          _id: cur.asset._id,
          count: 0,
          title: cur.asset.locales.en.title,
        };
        acc[cur.asset._id].count++;
      }
      return acc;
    }, {});

    console.log({ aggregatedCounts });
    setData(Object.values(aggregatedCounts) as any);
  }, [eventsRegistered.data?.data]);

  return (
    <Card bordered={true} style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "100" }}>
        {t("EVENT_REGISTRATIONS", "Event Registrations")}
      </h2>
      <Table
        loading={eventsRegistered.isLoading}
        columns={columns}
        dataSource={data}
      />
    </Card>
  );
};
