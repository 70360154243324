import React from "react";
import { Grid } from "@mui/material";
import { Button, Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";
// import { Calendar } from "antd";
// import Calendar from "src/Components/Calendar/Calendar";
import OnThisWeek from "src/Components/OnThisWeek";
import dayjs from "dayjs";
import EventTooltip from "src/Components/EventTooltip/EventTooltip";
import Timestamp from "src/Components/Timestamp/Timestamp";
import NiceModal from "@ebay/nice-modal-react";
import WelcomeModal from "../../Modals/Onboarding/01.Thanks";
import GetInfoModal from "../../Modals/Onboarding/02.CollectInfo";
import NewUserSteps from "src/Components/NewUserSteps/NewUserSteps";
import Event from "src/Components/EventCard";
import ArticleCard from "src/Components/ArticleCard";
import { useQuery } from "@tanstack/react-query";
import { listEvents } from "src/Services/Api/event.api";
import { listContent } from "src/Services/Api/content.api";
import VideoCard from "src/Components/VideoCard/VideoCard";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import ExpertCard from "src/Components/ExpertCard/ExpertCard";
import { listExperts } from "src/Services/Api/expert.api";

const Events = () => {
  const { t, i18n } = useTranslation();
  // const [events, setEvents] = useState<Record<string, any>[]>([]);

  const fetchEvents = async ({ queryKey }) => {
    const params = {
      where: {
        ...queryKey[1],
        "meta.date": { $gte: dayjs().startOf("month").format() },
        status: { $eq: "PUBLISHED" },
        type: "EVENT",
      },
      limit: 100,
      populate: "image author tags category",
    };

    return listContent(params);
  };

  const fetchArticles = async ({ queryKey }) => {
    const params = {
      where: {
        type: "ARTICLE",
        ...queryKey[1],
      },
      limit: 100,
      populate: "image author category tags",
    };

    return listContent(params);
  };

  const fetchVideos = async ({ queryKey }) => {
    const params = {
      where: {
        type: "VIDEO",
        ...queryKey[1],
      },
      limit: 100,
      populate: "image author",
    };

    return listContent(params);
  };

  const fetchExperts = async ({ queryKey }) => {
    const params = {
      where: {
        ...queryKey[1],
      },
      limit: 100,
      populate: "image",
    };

    return listExperts(params);
  };

  // const events = useQuery({
  //   queryKey: ["events"],
  //   queryFn: fetchEvents,
  // });

  const featuredEvents = useQuery({
    queryKey: ["events"],
    queryFn: fetchEvents,
  });

  const featuredArticles = useQuery({
    queryKey: ["articles", { isFeatured: true }],
    queryFn: fetchArticles,
  });

  const featuredVideos = useQuery({
    queryKey: ["videos", { isFeatured: true }],
    queryFn: fetchVideos,
  });

  const featuredExperts = useQuery({
    queryKey: ["experts", { isFeatured: true }],
    queryFn: fetchExperts,
  });

  const showWelcomeModal = () => {
    NiceModal.show(WelcomeModal).then(() => {
      NiceModal.show(GetInfoModal);
    });
  };

  return (
    <>
      <Grid
        container
        // spacing={2}
        style={{
          // padding: "20px",
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            padding: "20px",
          }}
        >
          {/* <div
            style={{ margin: "30px", display: "flex", flexDirection: "row" }}
          > */}

          {/* <div style={{ marginTop: "60px" }}>
            <Divider />
            <h1 style={{ marginBottom: "20px" }}>
              {t("YOUR_FIRST_STEPS", "Your first steps...")}
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <NewUserSteps showWelcomeModal={showWelcomeModal} />
            </div>
          </div> */}
          {/* </div> */}
          {/* <Divider /> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              borderRadius: "20px",
              // backgroundImage: "linear-gradient(45deg, #DE6572, #EFA891)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                borderRadius: "20px",
                backgroundImage: "linear-gradient(45deg, #65BEE9, #DAE5F1)", //"linear-gradient(45deg, #F89780, #EEB9A9)",
              }}
            >
              <img
                src={"/images/dance.png"}
                style={{
                  maxWidth: "200px",
                }}
              />
              <div
                style={{
                  margin: "20px",
                  marginTop: "20px",
                  marginBottom: "0px",
                  padding: "20px",
                  maxWidth: "600px",
                  height: "max-content",
                }}
              >
                <h2 style={{ fontWeight: 900, color: "#000" }}>
                  {t("TIP_OF_THE_DAY", "Tip of the day")}
                </h2>
                <p style={{ fontWeight: 100, fontSize: "18px", color: "#000" }}>
                  {" "}
                  Take regular breaks to daydream and let your mind wander.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            padding: "20px",
          }}
        >
          <h2 style={{ fontWeight: 900, color: "#000" }}>
            {t("UPCOMING_EVENTS", "Upcoming Events")}
          </h2>

          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <OnThisWeek />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
          style={{
            width: "100%",
            backgroundColor: "#F7F4F3",
          }}
        >
          <div
            style={{
              padding: "40px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2
                style={{ fontWeight: 900, color: "#000", marginBottom: "20px" }}
              >
                {t("UPCOMING_EVENTS", "Upcoming Events")}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {!featuredEvents.isPending &&
                featuredEvents.data?.data.map((event, index) => {
                  if (index > 4) {
                    return <div></div>;
                  }
                  return <Event event={event} />;
                })}
            </div>
            <Button
              type="link"
              style={{
                borderRadius: "20px",
                height: "40px",
                margin: "10px",
                fontWeight: 900,
                color: "#347ACO",
              }}
            >
              <Link
                to={"/dashboard/events"}
                style={{
                  fontWeight: 900,
                  color: "#000",
                  height: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("SEE_MORE", "See More")}
                <FontAwesomeIcon
                  style={{
                    height: "18px",
                    marginLeft: "5px",
                    paddingTop: "3px",
                  }}
                  icon={faAngleRight}
                />
              </Link>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            width: "100%",
            backgroundColor: "#F7F4F3",
          }}
        >
          <div
            style={{
              // margin: "30px",
              // backgroundColor: "#E6F1F8",
              padding: "40px",
              // borderRadius: "20px",
              // backgroundImage: "linear-gradient(45deg, #e7b7ba, #f4ddd5",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2
                style={{ fontWeight: 900, color: "#000", marginBottom: "20px" }}
              >
                {t("FEATURED_ARTICLES", "Featured Articles")}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {!featuredArticles.isPending &&
                featuredArticles.data.data.map((article) => {
                  return <ArticleCard article={article} />;
                })}
            </div>
            <Button
              type="link"
              style={{ borderRadius: "20px", height: "40px", margin: "10px" }}
            >
              <Link
                to={"/dashboard/articles"}
                style={{
                  fontWeight: 900,
                  color: "#000",
                  height: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("SEE_MORE", "See More")}
                <FontAwesomeIcon
                  style={{
                    height: "18px",
                    marginLeft: "5px",
                    paddingTop: "3px",
                  }}
                  icon={faAngleRight}
                />
              </Link>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            width: "100%",
            backgroundColor: "#F7F4F3",
          }}
        >
          <div
            style={{
              // margin: "30px",
              // backgroundColor: "#E6F1F8",
              padding: "40px",
              // borderRadius: "20px",
              // backgroundImage: "linear-gradient(45deg, #e7b7ba, #f4ddd5",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2
                style={{ fontWeight: 900, color: "#000", marginBottom: "20px" }}
              >
                {t("FEATURED_VIDEOS", "Featured Videos")}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {!featuredVideos.isPending &&
                featuredVideos.data.data.map((video) => {
                  return <VideoCard video={video} />;
                })}
            </div>
            <Button
              type="link"
              style={{ borderRadius: "20px", height: "40px", margin: "10px" }}
            >
              <Link
                to={"/dashboard/videos"}
                style={{
                  fontWeight: 900,
                  color: "#000",
                  height: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("SEE_MORE", "See More")}
                <FontAwesomeIcon
                  style={{
                    height: "18px",
                    marginLeft: "5px",
                    paddingTop: "3px",
                  }}
                  icon={faAngleRight}
                />
              </Link>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            width: "100%",
            backgroundColor: "#F7F4F3",
          }}
        >
          <div
            style={{
              // margin: "30px",
              // backgroundColor: "#E6F1F8",
              padding: "40px",
              // borderRadius: "20px",
              // backgroundImage: "linear-gradient(45deg, #e7b7ba, #f4ddd5",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2
                style={{ fontWeight: 900, color: "#000", marginBottom: "20px" }}
              >
                {t("FEATURED_EXPERTS", "Featured Experts")}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {!featuredExperts.isPending &&
                featuredExperts.data.data.map((expert) => {
                  return <ExpertCard expert={expert} />;
                })}
            </div>
            <Button
              type="link"
              style={{ borderRadius: "20px", height: "40px", margin: "10px" }}
            >
              <Link
                to={"/dashboard/experts"}
                style={{
                  fontWeight: 900,
                  color: "#000",
                  height: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("SEE_MORE", "See More")}
                <FontAwesomeIcon
                  style={{
                    height: "18px",
                    marginLeft: "5px",
                    paddingTop: "3px",
                  }}
                  icon={faAngleRight}
                />
              </Link>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Events;
