import React, { useState } from "react";
import { Table, Button, Tag, Dropdown } from "antd";
import type { TableProps, MenuProps } from "antd";
import NiceModal from "@ebay/nice-modal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-thin-svg-icons";
import { useTranslation } from "react-i18next";
import { listContent } from "src/Services/Api/content.api";
import { listTopics } from "src/Services/Api/topic.api";
import { useQuery } from "@tanstack/react-query";
import { listCategories } from "src/Services/Api/category.api";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ContentModal from "src/Modals/content.modal";

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: any; //Parameters<GetProp<TableProps, "onChange">>[1];
}

const Articles = () => {
  const { t, i18n } = useTranslation();

  const items: MenuProps["items"] = [
    { key: "1", label: t("EDIT", "Edit") },
    // { key: "2", label: "Delete", disabled: true },
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchArticles = async ({ queryKey }) => {
    const [_key, tableParams] = queryKey;

    console.log({ tableParams });

    return listContent({
      where: { ...tableParams.filters, type: "ARTICLE" },
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      sort: { createdAt: "desc" },
      populate: "image tags category author",
    });
  };

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["articles", tableParams],
    queryFn: fetchArticles,
  });

  const { data: tags } = useQuery({
    queryKey: ["topics"],
    queryFn: listTopics,
  });

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: listCategories,
  });

  const showArticleModal = (article?) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(ContentModal, { content: article, type: "ARTICLE" }).then(
      () => refetch()
    );
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters: any,
    sorter
  ) => {
    if (filters.tags && filters.tags.length) {
      filters.tags = { $in: filters.tags };
    } else {
      filters.tags = undefined;
    }

    if (filters.categories && filters.categories.length) {
      filters.category = { $in: filters.categories };
    } else {
      filters.category = undefined;
    }

    if (filters.category === null) {
      filters.category = undefined;
    }

    if (filters.tags === null) {
      filters.tags = undefined;
    }

    if (!filters.langage) {
      filters.language = undefined;
    }
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    refetch();
  };

  const handleMenuClick = (e, row) => {
    if (e.key === "1") {
      showArticleModal(row);
    }
  };

  const columns = [
    {
      title: t("IMAGE", "Image"),
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <img
            style={{
              width: "60px",
              margin: "5px",
              borderRadius: "5px",
            }}
            src={image?.thumbnailUrl}
          />
        );
      },
    },
    {
      title: t("TITLE", "Title"),
      dataIndex: "locales",
      key: "title",
      render: (locales, row) => {
        const locale =
          locales[i18n.language.split("-")[0] || "en"] || locales.en;

        if (!locale) {
          return <div></div>;
        }
        const { isFeatured } = row;
        return (
          <div>
            {isFeatured ? (
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                color="orange"
                style={{ width: "40px", margin: "0px" }}
              />
            ) : (
              <div></div>
            )}
            <Link to={`/dashboard/articles/${row._id}`}>{locale.title}</Link>
          </div>
        );
      },
    },
    {
      title: t("AUTHOR", "Author"),
      dataIndex: "author",
      key: "author",
      render: (author) => {
        return <div>{`${author.firstName} ${author.lastName}`}</div>;
      },
    },
    {
      title: t("TAGS", "Tags"),
      dataIndex: "tags",
      key: "tags",
      render: (tags) => {
        return (
          <div>
            {tags?.map((tag) => {
              return <Tag bordered={false}>{t(tag.name)}</Tag>;
            })}
          </div>
        );
      },
      filters: tags?.data?.map((t) => {
        return {
          text: t.name,
          value: t._id,
        };
      }),
    },
    {
      title: t("CATEGORY", "Category"),
      dataIndex: "category",
      key: "category",
      render: (category) => {
        return (
          <div>
            <Tag bordered={false}>{t(category.name)}</Tag>
          </div>
        );
      },
      filters: categories?.data?.map((c) => {
        return {
          text: c.name,
          value: c._id,
        };
      }),
    },
    {
      title: t("LANGUAGES", "Languages"),
      dataIndex: "locales",
      key: "language",
      render: (locales) => {
        return (
          <div>
            {Object.keys(locales).map((l) => {
              return <Tag>{l}</Tag>;
            })}
          </div>
        );
      },
      filters: [
        {
          text: t("en"),
          value: "en",
        },
        {
          text: t("fr"),
          value: "fr",
        },
      ],
    },
    {
      title: t("STATUS", "Status"),
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(e, row);
              },
            }}
          >
            <a>
              <FontAwesomeIcon icon={faBars} size="xl" color="#5B9CD1" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1>{t("ARTICLES", "Articles")}</h1>
          <Button
            type="primary"
            onClick={() => {
              showArticleModal();
            }}
          >
            {t("CREATE_ARTICLE", "Create Article")}
          </Button>
        </div>
      </div>
      <Table
        dataSource={data?.data}
        columns={columns}
        loading={isPending}
        pagination={{ ...tableParams.pagination, total: data?.count }}
        onChange={handleTableChange}
      />
      ;
    </div>
  );
};

export default Articles;
