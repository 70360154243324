import React, { useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const Callback = () => {
  const navigate = useNavigate();
  const validateLogin = async () => {
    console.log("Validating Login");

    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    console.log({ idToken });

    if (idToken) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    validateLogin();
  }, []);

  console.log("Callback Page");

  return <div></div>;
};

export default Callback;
