import React from "react";
import { Card, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userContext } from "src/userContext";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { updateUser } from "src/Services/Api/user.api";
import duration from "dayjs/plugin/duration";
import moment from "moment";
import dayjs from "dayjs";
import { createMetric } from "src/Services/Api/metric";
dayjs.extend(duration);

const YTDurationToSeconds = (duration) => {
  var match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  match = match.slice(1).map(function (x) {
    if (x != null) {
      return x.replace(/\D/, "");
    }
  });

  var hours = parseInt(match[0]) || 0;
  var minutes = parseInt(match[1]) || 0;
  var seconds = parseInt(match[2]) || 0;

  return hours * 3600 + minutes * 60 + seconds;
};

const VideoCard = ({ video }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { _id, image, meta, tags, locales, author } = video;

  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;

  const url = image.url;

  const date = new Date(0);

  const seconds = meta.duration ? YTDurationToSeconds(meta.duration) : 0;

  console.log({ seconds });
  console.log(date.toISOString());
  date.setSeconds(seconds); // specify value for SECONDS here
  const time = date.toISOString().slice(11, 19);

  console.log({ duration });
  return (
    <userContext.Consumer>
      {({ user, refetch }: { user: any; refetch: any }) => {
        return (
          <Card
            hoverable
            style={{
              width: 240,
              textWrap: "pretty",
              margin: "10px",
            }}
            cover={
              <img
                alt="example"
                src={url}
                style={{ height: "140px", objectFit: "cover" }}
              />
            }
            onClick={() => {
              navigate(`/dashboard/videos/${_id}`);
            }}
          >
            <div
              style={{ position: "absolute", top: 0, left: 0, margin: "10px" }}
            >
              {tags.map((tag) => {
                return (
                  <Tag color="#fff" style={{ color: "#000", opacity: "0.9" }}>
                    {t(tag.name)}
                  </Tag>
                );
              })}
            </div>

            <div
              style={{
                position: "absolute",
                top: "105px",
                right: "10px",
                padding: "6px",
                paddingTop: "2px",
                paddingBottom: "2px",
                backgroundColor: "#fff",
                opacity: "0.9",
                borderRadius: "10px",
              }}
            >
              {time}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  height: "70px",
                  textOverflow: "ellipsis",
                  marginBottom: "10px",
                }}
              >
                {locale.title}
              </div>
              <div>
                {user.favourites?.videos?.includes(_id)
                  ? [
                      <HeartFilled
                        key="setting"
                        style={{ color: "#F89780", fontSize: "20px" }}
                        onClick={async (e) => {
                          e.stopPropagation();

                          await updateUser({
                            favourites: {
                              ...user.favourites,
                              videos: user.favourites.videos.filter(
                                (id) => id !== _id
                              ),
                            },
                          });
                          await refetch();
                        }}
                      />,
                    ]
                  : [
                      <HeartOutlined
                        key="setting"
                        style={{ fontSize: "20px" }}
                        onClick={async (e) => {
                          e.stopPropagation();

                          await createMetric({
                            type: "video.like",
                            asset: _id,
                          });

                          await updateUser({
                            favourites: {
                              ...user.favourites,
                              videos: [...user.favourites.videos, _id],
                            },
                          });
                          await refetch();
                        }}
                      />,
                    ]}
              </div>
            </div>
            <div style={{ color: "#999", marginTop: "5px" }}>
              {author.firstName || ""} {author.lastName || ""}
            </div>
          </Card>
        );
      }}
    </userContext.Consumer>
  );
};

export default VideoCard;
