import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Button, Popconfirm, Skeleton } from "antd";
import EventDetails from "../../Components/EventDetails";
import EventRate from "../../Components/EventRate";
import { Link, useParams } from "react-router-dom";
import useUser, { getUser } from "src/Services/Api/user.api";
import EventRegistrationModal from "../../Modals/EventRegistration";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createMetric } from "src/Services/Api/metric";
import { useTranslation } from "react-i18next";
import { getResource } from "src/Services/Api/resource.get";
import { listContent } from "src/Services/Api/content.api";
import VideoCard from "src/Components/VideoCard/VideoCard";
import dayjs from "dayjs";

const Event = () => {
  const { i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState<Record<string, any>>();
  const [loading, setLoading] = useState(false);

  const { event_id } = useParams();

  const { t } = useTranslation();

  const { registerUserForEvent, unregisterUserForEvent } = useUser();

  const fetchEvent = async ({ queryKey }) => {
    const [key, { id }] = queryKey;

    return getResource(
      { queryKey },
      {
        populate: "image author category",
      }
    );
  };

  const fetchEventVideo = async ({ queryKey }) => {
    const [key, { id }] = queryKey;

    return listContent({
      where: {
        type: "VIDEO",
        "meta.event": { $eq: event_id },
      },
      populate: "image",
    });
  };

  const event = useQuery({
    queryKey: ["content", { id: event_id }],
    queryFn: fetchEvent,
  });

  const video = useQuery({
    queryKey: ["content", "meta.event"],
    queryFn: fetchEventVideo,
  });

  console.log("video data");
  console.log(video.data);

  const fetchUser = async () => {
    const { data } = await getUser({ id: "me" });
    setUser(data);
  };

  const mutation = useMutation({
    mutationFn: () => {
      return createMetric({
        type: "event.read",
        asset: event_id,
      });
    },
  });

  const addRegisterMetric = useMutation({
    mutationFn: () => {
      return createMetric({
        type: "event.register",
        asset: event_id,
      });
    },
  });

  useEffect(() => {
    mutation.mutate();
    fetchUser();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const isPast = () => {
    if (!event.data?.data) {
      return false;
    }

    if (dayjs(event.data?.data.meta.date).isBefore(dayjs())) {
      return true;
    }

    return false;
  };

  const handleRegister = async () => {
    setLoading(true);
    // Register user for event
    if (user!.registeredEvents.includes(event_id)) {
      const { data } = await unregisterUserForEvent(event_id);
      console.log("Got new user from unregister event");
      setUser(data);
      setLoading(false);
    } else {
      const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
      if (!userInfo.email) {
        throw new Error("User has no email address");
      }
      const { data } = await registerUserForEvent(event_id, userInfo.email);
      console.log("Got new user from register event");
      setUser(data);
      setLoading(false);
      showModal();
      addRegisterMetric.mutate();
    }
  };

  if (!event.data?.data || !user)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={7}>
          <div style={{ display: "flex", margin: "20px", width: "100%" }}>
            <Skeleton.Image
              active
              style={{
                height: "360px",
                width: "100%",
              }}
            />
          </div>
          <Skeleton active />
        </Grid>
        <div style={{ height: "100%", display: "flex" }}></div>
      </Grid>
    );

  if (!event.data?.data._id)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Sorry, the event you're looking for cannot be found</h1>
        <span style={{ fontWeight: "100" }}>
          Back to <Link to="/dashboard/events">events</Link>
        </span>
      </div>
    );

  const isRegistered = user.registeredEvents.includes(event_id);
  const title = isRegistered ? "Unregister for Event" : "Register for Event";

  const RegBtn = ({ onClick }) => {
    return (
      <Button
        type="primary"
        size="large"
        style={{ color: "#fff", backgroundColor: "#598BBC", fontWeight: "500" }}
        loading={loading}
        onClick={onClick}
      >
        {title}
      </Button>
    );
  };

  const RegisterBtn = () => {
    const { t } = useTranslation();
    if (isRegistered) {
      return <RegBtn onClick={handleRegister} />;
    } else {
      return (
        <Popconfirm
          title={t("PLEASE_CONFIRM", "Please Confirm")}
          description={t(
            "CONFIRM_YOU_WISH_TO_REGISTER",
            "Confirm you wish to register for this event"
          )}
          onConfirm={handleRegister}
        >
          <RegBtn onClick={() => {}} />
        </Popconfirm>
      );
    }
  };

  const locale =
    event.data?.data.locales[i18n.language.split("-")[0] || "en"] ||
    event.data?.data.locales.en;

  return (
    <>
      <EventRegistrationModal
        event={event}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={7}>
          <div style={{ display: "flex", margin: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                backgroundImage: `url(${event.data?.data.image.url})`,
                height: "360px",
                width: "100%",
                backgroundRepeat: "no-repeat",
                // @ts-ignore
                backgroundColor: (t): string | number =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "20px",
              }}
            >
              <h1 style={{ fontWeight: 900, color: "#fff", padding: "20px" }}>
                {locale.title}
              </h1>
            </div>
          </div>
          <div style={{ margin: "20px" }}>
            {user && !isPast() && <RegisterBtn />}
          </div>
          <div style={{ margin: "20px" }}>
            {t("RUN_BY", "Run by")}
            <Link
              type="link"
              style={{ padding: "8px" }}
              to={`/dashboard/experts/${event.data?.data.author._id}`}
            >
              {event?.data?.data.author.firstName}{" "}
              {event?.data?.data.author.lastName}
            </Link>
          </div>
          <div
            style={{ margin: "20px" }}
            dangerouslySetInnerHTML={{ __html: locale.description }}
          ></div>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          {event.data?.data && <EventDetails event={event.data?.data} />}
          {event.data?.data && isPast() && (
            <EventRate event={event.data?.data} />
          )}
        </Grid>

        <div style={{ height: "100%", display: "flex" }}></div>
      </Grid>
      {video.data?.data && video.data?.data.length > 0 && (
        <VideoCard video={video.data?.data[0]} />
      )}
    </>
  );
};

export default Event;
