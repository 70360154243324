import React from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { TotalUsers } from "src/Components/Analytics/NumberCards/TotalUsers";
import { ActiveUsers } from "src/Components/Analytics/NumberCards/ActiveUsers";
import { ArticlesRead } from "src/Components/Analytics/NumberCards/ArticlesRead";
import { ArticlesLiked } from "src/Components/Analytics/NumberCards/ArticlesLiked";
import { ArticlesLikedByCategory } from "src/Components/Analytics/PieCharts/ArticlesLikedByCategory";
import { ArticlesReadByCategory } from "src/Components/Analytics/PieCharts/ArticlesReadByCategory";
import { EventsRead } from "src/Components/Analytics/NumberCards/EventsRead";
import { EventsRegistered } from "src/Components/Analytics/NumberCards/EventsRegistered";
import { EventRegistration } from "src/Components/Analytics/LineCharts/EventRegistrations";
import { TopEvents } from "src/Components/Analytics/TopEvents";
import { VideosViewed } from "src/Components/Analytics/NumberCards/VideosViewed";
import { VideosLiked } from "src/Components/Analytics/NumberCards/VideosLiked";
import { VideosReadByCategory } from "src/Components/Analytics/PieCharts/VideosReadByCategory";
import { EventVideosViewed } from "src/Components/Analytics/NumberCards/EventVideosViewed";

const Analytics = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: "40px" }}>
      <h1>{t("USERS", "Users")}</h1>
      <div style={{ display: "flex" }}>
        <TotalUsers user={user} />
        <ActiveUsers user={user} />
      </div>

      <Divider />
      <h1>{t("ARTICLES", "Articles")}</h1>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ArticlesRead user={user} />
          <ArticlesLiked user={user} />
        </div>
        {/* <ArticlesLikedByCategory user={user} /> */}
        <ArticlesReadByCategory user={user} />
      </div>

      <Divider />
      <h1>{t("VIDEOS", "Videos")}</h1>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <VideosViewed user={user} />
          <VideosLiked user={user} />
        </div>
        {/* <ArticlesLikedByCategory user={user} /> */}
        <VideosReadByCategory user={user} />
      </div>

      <Divider />
      <h1>{t("EVENTS", "Events")}</h1>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <EventsRead user={user} />
          <EventsRegistered user={user} />
        </div>
        <EventRegistration user={user} />
        <EventVideosViewed user={user} />
      </div>
      <div>
        <TopEvents user={user} />
      </div>
    </div>
  );
};

export default Analytics;
