import NiceModal from "@ebay/nice-modal-react";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import EventsTable from "src/Components/EventsUpcomingTable";
import EventModal from "../../Modals/content.modal";
import dayjs from "dayjs";
import { listContent } from "src/Services/Api/content.api";
import EventsCompletedTable from "src/Components/EventsCompletedTable";

const EventsList = ({ user }: { user?: any }) => {
  const { t } = useTranslation();

  const showEventModal = async (event?) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(EventModal, { content: event, user, type: "EVENT" });
  };

  const queryFn = async ({ queryKey }) => {
    const [_key, tableParams] = queryKey;

    const where = {
      ...tableParams.filters,
      type: "EVENT",
      "meta.date": { $lt: dayjs().format() },
    };

    if (user) {
      where.availableFor = { $in: user.company._id };
    }

    return listContent({
      where,
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      populate: "image tags category author reviews",
    });
  };

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1>{t("UPCOMING_EVENTS", "Upcoming Events")}</h1>

        <Button
          type="primary"
          onClick={() => {
            showEventModal();
          }}
        >
          {t("CREATE_EVENT", "Create Event")}
        </Button>
      </div>
      <EventsCompletedTable queryFn={queryFn} />;
    </div>
  );
};

export default EventsList;
