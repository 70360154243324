import React, { useState } from "react";
import useImageHook from "../../Services/Api/image.api";
import { IContent, Image } from "src/Types/Database.types";
import type { GetProp, UploadProps } from "antd";
import { message, Form, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const ImageUpload = ({
  content,
  imageId,
  setImageId,
}: {
  content?: IContent;
  imageId: string;
  setImageId: Function;
}) => {
  const { createImage, updateImage } = useImageHook();

  console.log({ content, imageId });

  const [imageLoading, setImageLoading] = useState(false);

  const handleUpload = async ({ file }) => {
    // GET request: presigned URL
    const { data } = await createImage();
    const { url, image } = data;

    console.log("image record created");
    console.log({ url, image });

    // PUT request: upload file to S3
    try {
      const result = await fetch(url, {
        method: "PUT",
        body: file,
      });
    } catch (err) {
      console.error(err);
    }

    setImageId(image._id);
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setImageLoading(true);
      return;
    }
  };

  return (
    <Form.Item<IContent>
      label="Image"
      name="image"
      rules={[{ required: content?.image ? false : true, message: "Required" }]}
    >
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={handleUpload}
      >
        {content?.image ? (
          <img
            src={(content.image as Image).thumbnailUrl}
            alt="image"
            style={{ width: "100%" }}
          />
        ) : imageId ? (
          <img
            src={`https://oxyzn-images-production.s3.eu-west-1.amazonaws.com/upload/${imageId}.jpg`}
            alt="image"
            style={{ width: "100%" }}
          />
        ) : (
          <button style={{ border: 0, background: "none" }} type="button">
            {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        )}
      </Upload>
    </Form.Item>
  );
};
