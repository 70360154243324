import React from "react";
import { NumberCard } from "./NumberCard";
import { useQuery } from "@tanstack/react-query";
import { listMetrics } from "src/Services/Api/metric";
import { useTranslation } from "react-i18next";

export const TotalUsers = ({ user }) => {
  const { t } = useTranslation();
  const fetchTotalUsers = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "user.login" },
        companyId: { $eq: user.company._id },
      },
      limit: 0,
    });
  };

  const registeredUsers = useQuery({
    queryKey: ["metrics"],
    queryFn: fetchTotalUsers,
  });

  return (
    <NumberCard
      loading={registeredUsers.isPending}
      count={
        registeredUsers.data?.data.reduce((acc, cur) => {
          if (!acc.includes(cur.userId)) {
            acc.push(cur.userId);
          }
          return acc;
        }, []).length
      }
      title={t("TOTAL_USERS", "Total Users")}
    />
  );
};
