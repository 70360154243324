import React from "react";
import { NumberCard } from "./NumberCard";
import { useQuery } from "@tanstack/react-query";
import { listMetrics } from "src/Services/Api/metric";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const EventVideosViewed = ({ user }) => {
  const { t } = useTranslation();
  const fetchVideosViewed = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "video.read" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
      populate: ["asset"],
    });
  };

  const videosRead = useQuery({
    queryKey: ["metrics", "video.read"],
    queryFn: fetchVideosViewed,
  });

  return (
    <NumberCard
      loading={videosRead.isPending}
      count={
        videosRead.data?.data.reduce((acc, cur) => {
          console.log({ acc, cur });
          if (!cur.asset) {
            return acc;
          }
          if (cur.asset.meta.type === "EVENT") {
            acc.push(cur);
          }
          return acc;
        }, []).count
      }
      title={t("REPLAYS_VIEWED", "Replays Viewed")}
    />
  );
};
