import React from "react";
import { NumberCard } from "./NumberCard";
import { listMetrics } from "src/Services/Api/metric";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const EventsRegistered = ({ user }) => {
  const { t } = useTranslation();
  const fetchEventsRegistered = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "event.register" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      populate: ["asset"],
      limit: 0,
    });
  };
  const eventsRegistered = useQuery({
    queryKey: ["events", "event.register"],
    queryFn: fetchEventsRegistered,
  });

  return (
    <NumberCard
      loading={eventsRegistered.isPending}
      count={eventsRegistered.data?.count}
      title={t("EVENTS_REGISTERED", "Events Registered")}
    />
  );
};
