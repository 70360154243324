import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { createCategory, updateCategory } from "src/Services/Api/category.api";
import { createUserInCompany } from "src/Services/Api/user.api";

interface User {
  email: string;
  firstName: string;
  lastName: string;
}

export default NiceModal.create(
  ({ user, companyId }: { user?: any; companyId: string }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    console.log({ form });
    return (
      <Modal
        title={user ? "Edit User" : "Create User"}
        onOk={async () => {
          const newUser = form.getFieldsValue();

          console.log({ newUser });

          newUser.email = newUser.email.toLowerCase();

          setLoading(true);
          let created;
          try {
            if (!user) {
              created = await createUserInCompany({ ...newUser, companyId });
            } else {
              // created = await updateCategory({
              //   ...newCategory,
              //   _id: category._id,
              // });
            }

            modal.resolve(created);
            modal.hide();
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }}
        confirmLoading={loading}
        open={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            email: user?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
          }}
          autoComplete="off"
        >
          <Form.Item<User>
            label="Email"
            name="email"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<User>
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<User>
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>

          <p>User will be emailed a password</p>
        </Form>
      </Modal>
    );
  }
);
