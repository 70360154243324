import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Dropdown } from "antd";
import type { TableProps, MenuProps } from "antd";
import { listExperts } from "src/Services/Api/expert.api";
import ExpertModal from "../../Modals/expert.modal";
import NiceModal from "@ebay/nice-modal-react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { faBars } from "@fortawesome/pro-light-svg-icons";

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: any; //Parameters<GetProp<TableProps, "onChange">>[1];
}

interface ExpertData {
  experts: Record<string, any>[];
  count: number;
  loading: boolean;
  error?: any;
}

const Experts = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<ExpertData>({
    experts: [],
    count: 0,
    loading: true,
    error: null,
  });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters: any,
    sorter
  ) => {
    console.log({ pagination, filters, sorter });

    if (filters.topics) {
      filters.topics = { $in: filters.topics };
    } else {
      filters.topics = undefined;
    }

    if (filters.categories) {
      filters.categories = { $in: filters.categories };
    } else {
      filters.categories = undefined;
    }

    if (!filters.langage) {
      filters.language = undefined;
    }
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.current !== tableParams.pagination?.current) {
      setData({ ...data, experts: [] });
    }
  };

  useEffect(() => {
    fetchExperts();
  }, [JSON.stringify(tableParams)]);

  const items: MenuProps["items"] = [
    { key: "1", label: t("EDIT", "Edit") },
    // { key: "2", label: "Delete", disabled: true },
  ];

  const handleMenuClick = (e, row) => {
    if (e.key === "1") {
      showExpertsModal(row);
    }
  };

  const fetchExperts = async () => {
    const { data: experts, count } = await listExperts({
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      populate: "image",
    });

    setData({ experts, count: count || 0, loading: false });
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: count,
      },
    });
  };

  const experts = useQuery({
    queryKey: ["experts"],
    queryFn: () => fetchExperts(),
  });

  const showExpertsModal = (expert?) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(ExpertModal, { expert }).then(() => experts.refetch());
  };

  const columns = [
    {
      title: t("IMAGE", "Image"),
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <img
            style={{
              width: "60px",
              margin: "5px",
              borderRadius: "5px",
            }}
            src={image?.thumbnailUrl}
          />
        );
      },
    },

    {
      title: t("NAME", "Name"),
      dataIndex: "firstName",
      key: "firstName",
      render: (name: string, row: any) => {
        return (
          <Link to={`/dashboard/experts/${row._id}`}>{`${row.firstName || ""} ${
            row.lastName || ""
          }`}</Link>
        );
      },
    },

    {
      title: t("INSURANCE", "Insurance"),
      dataIndex: "insurance",
      key: "insurance",
      render: (insurance) => {
        return (
          <>
            {insurance ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="lg"
                color="green"
                style={{ width: "40px", marginRight: "20px" }}
              />
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
    {
      title: t("STATUS", "Status"),
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(e, row);
              },
            }}
          >
            <a>
              <FontAwesomeIcon icon={faBars} size="xl" color="#5B9CD1" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1>{t("EXPERTS", "Experts")}</h1>
          <Button
            type="primary"
            onClick={() => {
              showExpertsModal();
            }}
          >
            {t("CREATE_EXPERT", "Create Expert")}
          </Button>
        </div>
      </div>
      <Table
        dataSource={data.experts}
        columns={columns}
        loading={data.loading}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
      ;
    </div>
  );
};

export default Experts;
