import axios from "axios";
import { baseApiUrl } from "../../constants";
import { getToken } from "./helper";
import qs from "qs";

export const listContent = async (searchParams) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/content/${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const findContent = async (id) => {
  const { data } = await axios.get(`${baseApiUrl}/content/${id}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const createContent = async (content) => {
  const { data } = await axios.post(`${baseApiUrl}/content/`, content, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const updateContent = async (content) => {
  const { data } = await axios.patch(
    `${baseApiUrl}/content/${content._id}`,
    content,
    {
      headers: {
        Authorization: await getToken(),
      },
    }
  );

  return data;
};

export const deleteContent = async (contentID: string) => {
  await axios.delete(`${baseApiUrl}/content/${contentID}`, {
    headers: {
      Authorization: await getToken(),
    },
  });
};
