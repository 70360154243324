import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import type { TableProps } from "antd";
import dayjs from "dayjs";
import EventModal from "../../Modals/content.modal";
import NiceModal from "@ebay/nice-modal-react";
import { useQuery } from "@tanstack/react-query";
import { deleteEvent, listEvents } from "../../Services/Api/event.api";
import _ from "lodash";
import { columns } from "./columns";
import { useTranslation } from "react-i18next";
import { deleteContent } from "src/Services/Api/content.api";

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: any;
}

export const EventsTable = ({ queryFn, showModal }) => {
  const { t, i18n } = useTranslation();

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const events: any = useQuery({
    queryKey: ["events", tableParams],
    queryFn,
  });

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters: any,
    sorter
  ) => {
    console.log({ pagination, filters, sorter });

    if (filters.topics) {
      filters.topics = { $in: filters.topics };
    } else {
      filters.topics = undefined;
    }

    if (filters.categories) {
      filters.categories = { $in: filters.categories };
    } else {
      filters.categories = undefined;
    }

    if (!filters.langage) {
      filters.language = undefined;
    }
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    events.refetch();
  };

  const handleMenuClick = async (e, row) => {
    if (e.key === "2") {
      showModal(row);
    }
    if (e.key === "3") {
      await deleteContent(row._id);
      events.refetch();
    }
  };

  if (events.error) {
    return <div>{events.error.message}</div>;
  }

  console.log(tableParams.pagination);

  return (
    <Table
      dataSource={events.data?.data.sort((a, b) => {
        if (dayjs(a.date).isBefore(dayjs(b.date))) {
          return -1;
        }
        return 1;
      })}
      columns={columns(handleMenuClick, t, i18n)}
      loading={events.isPending}
      pagination={{ ...tableParams.pagination, total: events.data?.count }}
      onChange={handleTableChange}
    />
  );
};

export default EventsTable;
